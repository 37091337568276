import React from 'react';
import { Link } from 'gatsby';

class Layout extends React.Component {
    render() {
        const { children } = this.props;
        const rootPath = `${__PATH_PREFIX__}/`;
        return <section>{children}</section>;
    }
}

export default Layout;
